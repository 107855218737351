<template>
  <div style="font-size: 12px; min-width: 1600px;">
    <!-- el-alert v-if="errMsg" :title="errMsg" type="error" center show-icon></el-alert-->
    <el-row>
    <el-col :span="4">
      <el-date-picker v-model="date" type="date" @change="changDate"></el-date-picker>
      <table border="0" cellpadding="0" cellspacing="0" style="margin:auto">
        <tr v-for="(item, index) in goalmodelList" :key="index" @click="changeMatch(item)" :class="matchId === item.id?'selectedstyle':''">
          <td>{{index+1}}</td>
          <td>{{item.leagueAbbName}}</td>
          <td>{{item.homeTeamAbbName}}VS{{item.awayTeamAbbName}}</td>
        </tr>
      </table>
    </el-col>
    <el-col :span="14">
      <!--el-select v-model="matchId" placeholder="Select" @change="changeMatch">
        <el-option v-for="item in goalmodelList" :key="item.id" :label="item.homeTeamAbbName + 'VS' + item.awayTeamAbbName" :value="item.id">
        <span style="float: left">{{ item.homeTeamAbbName }} VS {{item.awayTeamAbbName}}</span>
          <span style=" float: right; color: var(--el-text-color-secondary); font-size: 13px;">
            {{ item.leagueAbbName }}
          </span>
        </el-option>
      </el-select-->
      <div style="text-align: center;font-size: 20px;">{{homeTeamAbbName}}VS{{awayTeamAbbName}}</div>
      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <!--tr>
          <td>项目</td>
          <td v-for="(item, index) in option.xAxis.data" :key="index">{{item}}</td>
        </tr>
        <tr>
          <td>竞彩官方</td>
          <td v-for="(item, index) in goalmodelOdds['竞彩官方']" :key="index">{{item}}</td>
        </tr>
        <tr>
          <td>比分换算</td>
          <td v-for="(item, index) in goalmodelOdds['比分换算']" :key="index">{{item}}</td>
        </tr>
        <tr>
          <td>万场</td>
          <td v-for="(item, index) in goalmodelOdds['万场']" :key="index">{{item}}</td>
        </tr-->
        <tr style="background-color: white;">
          <td class="tableSoccer">胜比分</td>
          <td class="tableSoccer">1:0</td>
          <td class="tableSoccer">2:0</td>
          <td class="tableSoccer">2:1</td>
          <td class="tableSoccer">3:0</td>
          <td class="tableSoccer">3:1</td>
          <td class="tableSoccer">3:2</td>
          <td class="tableSoccer">4:0</td>
          <td class="tableSoccer">4:1</td>
          <td class="tableSoccer">4:2</td>
          <td class="tableSoccer">胜其他</td>
          <td class="tableSoccer" rowspan="4" :style="'background-color:' + bifenluojiOdds['百分比平均索引'][0] + ';'">{{ towNumber(bifenluojiOdds['百分比平均'][0] * 100.0, 2) }}%</td>
        </tr>
        <tr>
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['辅助一胜']" :key="index" :style="'background-color:' +  + ';'">{{towNumber(item/100.0, 2)}}</td>
        </tr>
        <tr>
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['辅助二胜']" :key="index" :style="'background-color:' + bkColor['模块二'][index] + ';'">{{item/100.0}}</td>
        </tr>
        <tr>
          <td class="tableSoccer">百分比</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['百分比胜']" :key="index" :style="'color: red; background-color:' + bifenluojiOdds['百分比索引'][index] + ';'">{{towNumber(item * 100, 2)}}%</td>
        </tr>
        <tr style="height: 2px;">
          <td colspan="12" style="padding: 0;"></td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">平比分</td>
          <td class="tableSoccer">0:0</td>
          <td class="tableSoccer">1:1</td>
          <td class="tableSoccer">2:2</td>
          <td class="tableSoccer">3:3</td>
          <td class="tableSoccer">平其他</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td class="tableSoccer" rowspan="4" :style="'background-color:' + bifenluojiOdds['百分比平均索引'][1] + ';'">{{ towNumber(bifenluojiOdds['百分比平均'][1] * 100.0, 2) }}%</td>
        </tr>
        <tr>
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['辅助一平']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{item/100.0}}</td>
        </tr>
        <tr>
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['辅助二平']" :key="index" :style="'background-color:' + bkColor['模块二'][index] + ';'">{{item/100.0}}</td>
        </tr>
        <tr>
          <td class="tableSoccer">百分比</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['百分比平']" :key="index" :style="'color: red; background-color:' + bifenluojiOdds['百分比索引'][index+10] + ';'">{{towNumber(item * 100, 2)}}%</td>
        </tr>
        <tr style="height: 2px; padding: 0;">
          <td colspan="12" style="padding: 0;"></td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">负比分</td>
          <td class="tableSoccer">0:1</td>
          <td class="tableSoccer">0:2</td>
          <td class="tableSoccer">1:2</td>
          <td class="tableSoccer">0:3</td>
          <td class="tableSoccer">1:3</td>
          <td class="tableSoccer">2:3</td>
          <td class="tableSoccer">0:4</td>
          <td class="tableSoccer">1:4</td>
          <td class="tableSoccer">2:4</td>
          <td class="tableSoccer">负其他</td>
          <td class="tableSoccer" rowspan="4" :style="'background-color:' + bifenluojiOdds['百分比平均索引'][2] + ';'">{{ towNumber(bifenluojiOdds['百分比平均'][2] * 100.0, 2) }}%</td>
        </tr>
        <tr>
          <td class="tableSoccer">模块一</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['辅助一负']" :key="index" :style="'background-color:' + bifenluojiOdds['百分比平均'][index] + ';'">{{towNumber(item/100.0, 2)}}</td>
        </tr>
        <tr>
          <td class="tableSoccer">模块二</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['辅助二负']" :key="index" :style="'background-color:' + bkColor['模块二'][index] + ';'">{{item/100.0}}</td>
        </tr>
        <tr>
          <td class="tableSoccer">百分比</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['百分比负']" :key="index" :style="'color: red; background-color:' + bifenluojiOdds['百分比索引'][index+15] + ';'">{{towNumber(item * 100, 2)}}%</td>
        </tr>

      </table>
      <div style="text-align: center;">模块三（辅助项）</div>
      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <tr>
          <td class="tableSoccer" rowspan="3">猛</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['猛胜']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{towNumber(item, 0)}}</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['猛索引'][0] + ';'">{{ towNumber(bifenluojiOdds['猛平均'][0], 0) }}</td>
        </tr>
        <tr>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['猛平']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{towNumber(item, 0)}}</td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['猛索引'][1] + ';'">{{ towNumber(bifenluojiOdds['猛平均'][1], 0) }}</td>
        </tr>
        <tr>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['猛负']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{towNumber(item, 0)}}</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['猛索引'][2] + ';'">{{ towNumber(bifenluojiOdds['猛平均'][2], 0) }}</td>
        </tr>
        <tr style="height: 2px; padding: 0;">
          <td colspan="12" style="padding: 0;"></td>
        </tr>
        <tr>
          <td class="tableSoccer" rowspan="3">士</td>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['士胜']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{towNumber(item, 0)}}</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['士索引'][0] + ';'">{{ towNumber(bifenluojiOdds['士平均'][0], 0) }}</td>
        </tr>
        <tr>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['士平']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{towNumber(item, 0)}}</td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer"></td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['士索引'][1] + ';'">{{ towNumber(bifenluojiOdds['士平均'][1], 0) }}</td>
        </tr>
        <tr>
          <td class="tableSoccer" v-for="(item, index) in bifenluojiOdds['士负']" :key="index" :style="'background-color:' + bkColor['模块一'][index] + ';'">{{towNumber(item, 0)}}</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['士索引'][2] + ';'">{{ towNumber(bifenluojiOdds['士平均'][2], 0) }}</td>
        </tr>
      </table>
      <table id="table" border=“1” cellpadding="0" cellspacing="0" style="font-weight:bold;">
        <tr style="background-color: gainsboro;">
          <td class="tableSoccer"></td>
          <td class="tableSoccer">0</td>
          <td class="tableSoccer">1</td>
          <td class="tableSoccer">2</td>
          <td class="tableSoccer">3</td>
          <td class="tableSoccer">4</td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">0</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][10] + ';'">{{towNumber(bifenluojiOdds['百分比平'][0] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][15] + ';'">{{towNumber(bifenluojiOdds['百分比负'][0] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][16] + ';'">{{towNumber(bifenluojiOdds['百分比负'][1] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][18] + ';'">{{towNumber(bifenluojiOdds['百分比负'][3] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][21] + ';'">{{towNumber(bifenluojiOdds['百分比负'][6] * 100, 0)}}%</td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">1</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][0] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][0] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][11] + ';'">{{towNumber(bifenluojiOdds['百分比平'][1] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][17] + ';'">{{towNumber(bifenluojiOdds['百分比负'][2] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][19] + ';'">{{towNumber(bifenluojiOdds['百分比负'][5] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][22] + ';'">{{towNumber(bifenluojiOdds['百分比负'][8] * 100, 0)}}%</td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">2</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][1] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][1] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][2] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][2] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][12] + ';'">{{towNumber(bifenluojiOdds['百分比平'][2] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][21] + ';'">{{towNumber(bifenluojiOdds['百分比负'][5] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][23] + ';'">{{towNumber(bifenluojiOdds['百分比负'][8] * 100, 0)}}%</td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">3</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][3] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][3] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][4] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][4] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][5] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][5] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][13] + ';'">{{towNumber(bifenluojiOdds['百分比平'][3] * 100, 0)}}%</td>
          <td class="tableSoccer">-</td>
        </tr>
        <tr style="background-color: white;">
          <td class="tableSoccer">4</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][6] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][6] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][7] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][7] * 100, 0)}}%</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][8] + ';'">{{towNumber(bifenluojiOdds['百分比胜'][8] * 100, 0)}}%</td>
          <td class="tableSoccer">-</td>
          <td class="tableSoccer" :style="'background-color:' + bifenluojiOdds['百分比索引'][14] + ';'">{{towNumber(bifenluojiOdds['百分比平'][4] * 100, 0)}}%</td>
        </tr>
      </table>
      <!--div style="text-align: left;">
        <p>一、X绝对值最低，Y绝对值最低及相间区域</p>
        <p>二、超过100%，百分比需关注</p>
      </div-->
    </el-col>
    <el-col :span="6">
      <div class="charts">
        <div id="auxiliary1" :style="{width: '680px', height: '260px'}"></div>
        <div class="oneDiff">差值</div>
        <div id="auxiliary2" :style="{width: '680px', height: '260px'}"></div>
        <div class="twoDiff">差值</div>
      </div>
      <!--div style="text-align: left;">
        <p>图标决策基础逻辑（通常双选）模块一定位，模块二辅助</p>
        <p>1.模一1-4球定位，2球点为中庸。</p>
        <p>2.模块二为大数据思维，正常为平滑曲线红线以上代表本场赔率低开，反之亦然，折线拐点和顶点需注意；</p>
        <p>3.模块一定位大球，模块二大球走势，优先大球，模块一定位小球，模块二小球拐点或定位点后的大球平滑，优先定位点及小；</p>
        <p>4.超大和0球往往体现在模块二；</p>
        <p>5.过往比赛可查可复盘，学习永远第一位；</p>
      </div-->
    </el-col>
    </el-row>
    <!--v-chart class="chart" :option="option1" /-->
  </div>
</template>

<script>
// 引入 echarts 核心模块，核心模块提供了 echarts 使用必须要的接口。
import * as echarts from 'echarts/core'
// 引入柱状图图表，图表后缀都为 Chart
import { BarChart, LineChart } from 'echarts/charts'
// 引入提示框，标题，直角坐标系，数据集，内置数据转换器组件，组件后缀都为 Component
import {
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  // DatasetComponentOption,
  TransformComponent,
  MarkLineComponent,
  GraphicComponent
} from 'echarts/components'
// 标签自动布局，全局过渡动画等特性
import { LabelLayout, UniversalTransition } from 'echarts/features'
// 引入 Canvas 渲染器，注意引入 CanvasRenderer 或者 SVGRenderer 是必须的一步
import { CanvasRenderer } from 'echarts/renderers'
// import { setBlockTracking } from 'vue'

import { ElMessage } from 'element-plus'
// 注册必须的组件
echarts.use([
  TitleComponent,
  TooltipComponent,
  GridComponent,
  DatasetComponent,
  TransformComponent,
  BarChart,
  LineChart,
  LabelLayout,
  UniversalTransition,
  CanvasRenderer,
  MarkLineComponent,
  GraphicComponent
])

const waterMarkText = '猛士之家进球数图例官网：mengshizhijia.com\n官方公众号：猛士之家'
const canvas = document.createElement('canvas')
const ctx = canvas.getContext('2d')
canvas.width = 700
canvas.height = 200
ctx.textAlign = 'center'
ctx.textBaseline = 'middle'
ctx.fillStyle = '#000'
ctx.fillRect(0, 0, 700, 200)
ctx.globalAlpha = 0.3
ctx.fillStyle = '#FFF'
ctx.font = '20px Microsoft Yahei'
ctx.translate(350, 100)
ctx.rotate(-10 * Math.PI / 180)
ctx.fillText(waterMarkText, 0, 0)

export default {
  data () {
    return {
      // errMsg: '',
      date: '',
      score: ['0', '1', '2', '3', '4', '5', '6', '7+'],
      goalmodelList: [],
      goalmodelListBackgroundColor: [],
      bifenluojiOdds: {
        猛平均: [],
        士平均: [],
        百分比平均: [],
        百分比平: [],
        百分比胜: [],
        百分比负: [],
        百分比索引: [],
        百分比平均索引: [],
        猛索引: [],
        士索引: []
      },
      goalmodelOdds: [],
      goalmodelOddsBackgroundColor: [],
      matchId: '',
      homeTeamAbbName: '',
      awayTeamAbbName: '',
      option: {
        backgroundColor: {
          type: 'pattern',
          image: canvas,
          repeat: 'repeat'
        },
        legend: {
          data: ['辅助一']
        },
        /* graphic: [{
          type: 'text',
          silent: true,
          z: 100,
          left: 'center',
          top: 'middle',
          style: {
            fill: 'yellow',
            text: [
              '猛士之家进球数图例官网：mengshizhijia.com',
              '官方公众号：猛士之家'
            ].join('\n'),
            fontSize: 14
          }
        }], */
        title: { text: '' },
        xAxis: {
          type: 'category',
          data: ['0', '1', '2', '3', '4', '5', '6', '7+'],
          name: '进球数',
          nameTextStyle: {
            color: '#FFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#333'],
              width: 1,
              type: 'solid'
            }
          }
        },
        yAxis: {
          type: 'value',
          name: '百分比',
          min: 50,
          max: 90,
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %',
            color: '#FFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#333333'],
              width: 1,
              type: 'solid'
            }
          }
        },
        label: {},
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          itemStyle: {
            normal: {
              label: {
                show: true,
                color: '#FFF',
                formatter: function (a, b, c) {
                  return a.data + '%'
                }
              }
            }
          },
          name: '模块一',
          data: [],
          type: 'line'
        }, {
          /* itemStyle: {
            normal: {
              label: {
                show: true,
                formatter: function (a, b, c) {
                  return a.data + '%'
                }
              }
            }
          }, */
          name: '模块一初始',
          data: [],
          type: 'line',
          lineStyle: {
            type: 'dashed',
            color: '#FFF'
          }
        }]
      },
      option2: {
        backgroundColor: {
          type: 'pattern',
          image: canvas,
          repeat: 'repeat'
        },
        legend: {
          data: ['辅助一']
        },
        title: { text: '' },
        xAxis: {
          type: 'category',
          data: ['0', '1', '2', '3', '4', '5', '6', '7+'],
          name: '进球数',
          nameTextStyle: {
            color: '#FFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#333'],
              width: 1,
              type: 'solid'
            }
          },
          axisLine: {
            color: 'red',
            type: 'solid',
            width: 8
          }
        },
        yAxis: {
          type: 'value',
          name: '',
          axisLabel: {
            show: true,
            interval: 'auto',
            color: '#FFF'
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: ['#333333'],
              width: 1,
              type: 'solid'
            }
          }
        },
        label: {},
        tooltip: {
          trigger: 'axis'
        },
        series: [{
          itemStyle: {
            normal: {
              label: {
                color: '#FFF',
                show: true
              }
            }
          },
          lineStyle: {
            type: 'solid'
          },
          name: '模块二',
          data: [],
          type: 'line',
          markLine: {
            silent: true,
            data: [{
              yAxis: 0,
              lineStyle: {
                color: 'red',
                type: 'dashed',
                width: 2
              }
            }]
          }
        }, {
          /* itemStyle: {
            normal: {
              label: {
                show: true
              }
            }
          }, */
          lineStyle: {
            type: 'dashed',
            color: '#FFF'
          },
          name: '模块二初始',
          data: [],
          type: 'line',
          markLine: {
            silent: true,
            data: [{
              yAxis: 0,
              lineStyle: {
                color: 'red',
                type: 'dotted',
                width: 2
              }
            }]
          }
        }]
        /* graphic: [{
          type: 'text',
          silent: true,
          z: 100,
          left: 'center',
          top: 'middle',
          style: {
            fill: 'yellow',
            text: [
              '猛士之家进球数图例官网：mengshizhijia.com',
              '官方公众号：猛士之家'
            ].join('\n'),
            fontSize: 14
          }
        }] */
      },
      dataSort: {
        模块一: ['', '', '', '', '', '', '', '', ''],
        模块二: ['', '', '', '', '', '', '', '', ''],
        W: ['', '', '', '', '', '', '', '', '']
      },
      colorValue: [
        ['#018f3d', '#01bc50', '#01fa6a', '#ffffff', '#ccd306', '#e7ef04', '#e8fb66'],
        ['#018f3d', '#01bc50', '#01fa6a', '#ffffff', '#ffffff', '#ccd306', '#e7ef04', '#e8fb66']
      ],
      bkColor: {
        模块一: [],
        模块二: [],
        百分比: [],
        W: []
      }
    }
  },
  mounted () {
    this.formatDate(new Date())
    this.getGoalmodelList()
    this.addWaterMarker('猛士之家进球数图例官网：mengshizhijia.com\n官方公众号：猛士之家')
  },
  methods: {
    addWaterMarker (str) {
      var can = document.createElement('canvas')
      var body = document.getElementById('app')
      body.appendChild(can)
      can.width = 600
      can.height = 200
      can.style.display = 'none'
      var cans = can.getContext('2d')
      cans.rotate(-8 * Math.PI / 180)
      cans.font = '16px Microsoft JhengHei'
      cans.fillStyle = 'rgba(17, 17, 17, 0.30)'
      cans.textAlign = 'left'
      cans.textBaseline = 'Middle'
      cans.fillText(str, 100, 100)
      body.style.backgroundImage = 'url(' + can.toDataURL('image/png') + ')'
    },
    towNumber (val, len) {
      if (val) {
        return val.toFixed(len)
      }
      return 0
    },
    formatNumber (n) {
      n = n.toString()
      return n[1] ? n : '0' + n
    },
    formatDate (date) {
      var year = date.getFullYear()
      var month = date.getMonth() + 1
      var day = date.getDate()
      this.date = [year, month, day].map(this.formatNumber).join('-')
    },
    getGoalmodelList () {
      this.$axios.get('api/goalmodel/list', {
        params: {
          date: this.date
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          ElMessage.error(temp.msg)
          // this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.goalmodelList = temp.data
        }
      })
    },
    changDate (event) {
      this.formatDate(this.date)
      this.getGoalmodelList()
    },
    submenu (event) {
      const auxiliary1 = echarts.init(document.getElementById('auxiliary1'))
      const auxiliary2 = echarts.init(document.getElementById('auxiliary2'))
      this.matchId = event.id
      this.$axios.get('api/goalmodel/odds', {
        params: {
          matchId: event.id
        }
      }).then(res => {
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          ElMessage.error(temp.msg)
          // this.errMsg = temp.msg
        } else if (temp.errcode === 0) {
          this.goalmodelOdds = temp.data
          for (let index = 0; index < this.goalmodelOdds['辅助一'].length; index++) {
            // if (this.goalmodelOdds['辅助一'][index] > )
          }
          this.option.title.text = '模块一'
          for (let index = 0; index < temp.data['辅助一'].length; index++) {
            // temp.data['辅助一'][index] += '%'
            this.option.xAxis.data[index] += '\n' + temp.data['辅助一'][index] + '%'
          }
          console.log(this.option)
          this.option.series[0].data = temp.data['辅助一']
          this.option.series[1].data = temp.data['辅助一初始']
          auxiliary1.setOption(this.option)
          this.option.title.text = '模块二'
          this.option.series[0].data = temp.data['辅助二']
          auxiliary2.setOption(this.option)
        }
      })
    },
    changeMatch (event) {
      console.log('changeMatch')
      console.log(event)
      this.matchId = event.id
      this.homeTeamAbbName = event.homeTeamAbbName
      this.awayTeamAbbName = event.awayTeamAbbName
      this.$axios.get('api/goalmodel/bifenluoji', {
        params: {
          matchId: event.id
        }
      }).then(res => {
        // console.log(res)
        // this.bifenluojiOdds = ''
        console.log(res)
        const temp = res.data
        if (temp.errcode === -1) {
          this.$router.push({ path: '/login' })
        } else if (temp.errcode > 0) {
          ElMessage.error(temp.msg)
          // this.errMsg = temp.msg
          this.bifenluojiOdds = temp.data
        } else if (temp.errcode === 0) {
          this.bifenluojiOdds = temp.data
        }
      })
    }
  }
}
</script>

<style>
  .echarts {
    width: 100%;
    height: 100%;
  }
  #table {
    border-collapse: collapse;
    border:thin;border-color: black;
    margin: 10px auto;
  }
  .tableSoccer {
    width: 80px;
  }
  .charts {
    margin: auto;
    display: flex;
    position: relative;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .oneDiff {
    position: relative;
    left: -280px;
    top: -40px;
    color: white;
    z-index: 9;
  }
  .twoDiff {
    position: relative;
    left: -280px;
    top: -40px;
    color: white;
    z-index: 9;
  }
  td {
    padding: 10px 12px;
  }
  .tableSoccer {
    padding: 3px 5px;
  }
  .selectedstyle {
    background-color: yellowgreen;
  }
</style>
